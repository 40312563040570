import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const OurHistory = () => {
  return (
    <section className="history-area ptb-100 bg-e7edf9">
      <div className="container">
        <div className="section-title">
          <h2>Istorija počinje 2016</h2>
        </div>

        <ol className="timeline history-timeline">
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2016</span>
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Ideja je rođena</h3>
                    <p>
                      Tafoma - naš novi softver za digitalni radni prostor:
                      kreiran iz naše zajedničke potrebe za radnim prostorom
                      prilagođenim korisniku, gde je saradnja ključni faktor.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <StaticImage
                      src={"../../assets/images/about/history1.png"}
                      alt="Person next to lightbulb."
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li className="timeline-block">
            <div className="timeline-date">
              <span>2018</span>
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Objavljena je beta verzija</h3>
                    <p>
                      Bili smo spremni da javnosti pokažemo našu sveobuhvatnu,
                      modernizovanu platformu. Želeli smo da svi iskuse kako će
                      naše neintegrativno softversko rešenje revolucionisati
                      njihov tim i upravljanje projektima.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <StaticImage
                      src={"../../assets/images/about/history2.png"}
                      alt="Two people siting on a cloud."
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li className="timeline-block">
            <div className="timeline-date">
              <span>2020</span>
            </div>

            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>

            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Objavljena je verzija Tafoma 1.0</h3>
                    <p>
                      Naoružani pozitivnim recenzijama krajnjih korisnika,
                      naporno smo radili na podešavanju i razvoju softvera i
                      konačno smo uspeli da izađemo iz beta verzije. Sa Tafomom
                      smo kreirali jedinstveni, efikasan veb-bazirani alat koji
                      objedinjuje sve potrebe radnog mesta; elegantno
                      dizajnirano digitalno rešenje koje olakšava produktivnost
                      i efikasnost. Sve što vam je potrebno za praćenje
                      projekata, upravljanje i saradnju u okviru jedne
                      platforme, bez traženja integracije treće strane:
                      zaljubite se u evoluciju digitalnog radnog mesta uz
                      Tafoma.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <StaticImage
                      src={"../../assets/images/about/history3.png"}
                      alt="Person next to phone."
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </section>
  );
};

export default OurHistory;
