import React, { Component } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 600,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      swipeToSlide: true,

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    };
    return (
      <section className="scientist-area pt-50 pb-50">
        <div className="container">
          <div className="section-title">
            <h2>Tim</h2>
            <p>Neka od lica iza Tafome.</p>
          </div>
          <Slider {...settings}>
            <div className="single-box1">
              <div className="single-scientist-item-box">
                <div className="image">
                  <StaticImage
                    src={"../../assets/images/scientist/scientist1.png"}
                    alt="Team-Member Todd"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="team-img-div"
                  />
                </div>
                <div className="content">
                  <h3>Тоде Б.</h3>
                  {/* <span>Plejmejker</span> */}
                </div>
              </div>
            </div>

            <div className="single-box1">
              <div className="single-scientist-item-box">
                <div className="image image2">
                  <StaticImage
                    src={"../../assets/images/scientist/scientist2.png"}
                    alt="Team-Member Goran"
                    formats={["AUTO", "WEBP", "AVIF"]}
                  />
                </div>
                <div className="content">
                  <h3>Горан Ц.</h3>
                  {/* <span>Centar</span> */}
                </div>
              </div>
            </div>

            {/* <div className="single-box1">
                            <div className="single-scientist-item-box">
                                <div className="image image2">
                                    <StaticImage src={'../../assets/images/scientist/scientist3.png'}
                                        alt="Team-Member Savica"
                                        formats={["AUTO", "WEBP", "AVIF"]} />
                                </div>
                                <div className="content">
                                    <h3>Савица Т.</h3>
                                    <span>Крило</span>
                                </div>
                            </div>
                        </div> */}

            <div className="single-box1">
              <div className="single-scientist-item-box">
                <div className="image image2">
                  <StaticImage
                    src={"../../assets/images/scientist/scientist4.png"}
                    alt="Team-Member Savica"
                    formats={["AUTO", "WEBP", "AVIF"]}
                  />
                </div>
                <div className="content">
                  <h3>Крис Б.</h3>
                  {/* <span>Beck</span> */}
                </div>
              </div>
            </div>

            <div className="single-box1">
              <div className="single-scientist-item-box">
                <div className="image image2">
                  <StaticImage
                    src={"../../assets/images/scientist/scientist5.png"}
                    alt="Team-Member Savica"
                    formats={["AUTO", "WEBP", "AVIF"]}
                  />
                </div>
                <div className="content">
                  <h3>Николина K.</h3>
                  {/* <span>Крилен Центар</span> */}
                </div>
              </div>
            </div>

            <div className="single-box1">
              <div className="single-scientist-item-box">
                <div className="image image2">
                  <StaticImage
                    src={"../../assets/images/scientist/scientist6.png"}
                    alt="Team-Member Savica"
                    formats={["AUTO", "WEBP", "AVIF"]}
                  />
                </div>
                <div className="content">
                  <h3>Александар Г.</h3>
                  {/* <span>Шести Човек</span> */}
                </div>
              </div>
            </div>

            <div className="single-box1">
              <div className="single-scientist-item-box">
                <div className="image image2">
                  <StaticImage
                    src={"../../assets/images/scientist/scientist7.png"}
                    alt="Team-Member Savica"
                    formats={["AUTO", "WEBP", "AVIF"]}
                  />
                </div>
                <div className="content">
                  <h3>Ивана С.</h3>
                  {/* <span>Крило</span> */}
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
