import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <h2>Svrha, misija i vizija</h2>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  {/* <h3>Data for All Your People</h3> */}
                  <p>
                    <span className="black-text">Svrha</span> - prihvatanje
                    prelaska industrije na desktop okruženje zasnovano na
                    oblaku, poboljšanje iskustva zaposlenih i radna okruženja
                    koja su spremna za budućnost.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  {/* <h3>A New Breed of AI</h3> */}
                  <p>
                    <span className="black-text">Misija</span> - praćenje
                    najnovijih trendova u tehnologiji, sa ciljem strateškog
                    ulaganja u istraživanje i razvoj, kao i stalnog usavršavanja
                    svih naših zaposlenih.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  {/* <h3>Analytics Business</h3> */}
                  <p>
                    <span className="black-text">Vizija</span> - širenje na
                    globalnom nivou, sa glavnim fokusom na kontinuirano
                    unapređenje naših usluga, kao i rast i razvoj kompanije -
                    zajedno sa svojim partnerima, zaposlenima i korisnicima. Sve
                    kako bi postali poznati brend.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <StaticImage
                src={"../../assets/images/about/pmv-img.png"}
                alt="Teammates collaborating."
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
