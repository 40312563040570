import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const AboutUsContent = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <StaticImage
                src={"../../assets/images/about/main.png"}
                alt="Group of people standing next to eachother."
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                loading="eager"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <h2>O nama</h2>
                <p className="text-justify">
                  Tafoma je tim iskusnih profesionalaca vođen istim ciljem: da
                  pokrije novo i razvijeno digitalno radno okruženje, sa
                  sve-u-jednom rešenje za timski rad i probleme upravljanja
                  projektima.
                </p>

                <p className="text-justify">
                  Inspirisani brzo rastućim promenama savremenog sveta, potrebom
                  za transparentnošću, zahtevima za efikasnim i produktivnim
                  timskim radnim okruženjem i našim iskustvom u ovoj oblasti,
                  kreirali smo Tafoma.
                </p>

                {/* <p className="text-justify">Now, we bring you our vision. <span className="black-text">Let us reinvent the working environment with you! </span></p> */}
                <p className="text-justify">
                  Sada vam predstavljamo našu viziju.{" "}
                  <span className="black-text">
                    Hajde da ponovo osmislimo radnu površinu zajedno!
                  </span>
                </p>
                <Link
                  to="/blog/who-is-behind-tafoma-meet-the-team"
                  className="default-btn"
                >
                  Više o nama<span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsContent;
